var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-autocomplete", {
        attrs: {
          value: _vm.selectedVendor,
          items: _vm.vendors,
          disabled: _vm.disabled,
          "item-text": "name",
          "item-value": "vendor_party_id",
          label: "Vendor",
          loading: _vm.loading,
          "search-input": _vm.vendorSearch,
          "background-color": "input",
          hint: _vm.searchEnabled ? "Start typing to search" : null,
          "hide-details": "auto",
          "hide-no-data": "",
          "cache-items": "",
          "return-object": "",
          clearable: "",
          dense: "",
          outlined: ""
        },
        on: {
          "update:searchInput": function($event) {
            _vm.vendorSearch = $event
          },
          "update:search-input": function($event) {
            _vm.vendorSearch = $event
          },
          change: function($event) {
            return _vm.$emit("update:selectedVendor", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }